<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-key -->
<template>
    <div>
        <block v-if="loading" />
        <div >
           

            <v-row :class="`mt-4 d-flex ${paddingXs}`">
                
              <!--   <v-col :cols="twoSlots ? 2 : 0" :md="!columns.length === 0 ? 6 : 10" :lg="!columns.length === 0 ? 6 : 10">
                  <v-row class="mt-2">
                    
                  </v-row>
                </v-col> -->
               
                

                <v-col cols="3" sm="6" md="1" lg="1" >
                    <v-checkbox v-if ="!disableDates"  v-model="enableDate" label="Filtrar Fecha" class="mx-5 pt-1"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" class="d-flex">
                    <date-picker  v-if ="!disableDates" :disbaled="!enableDate"  label="Fecha inicio" @dateSelected="(param) => {startDate = param; $emit('getStart', param) }" class="mx-5" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" >
                    <date-picker v-if ="!disableDates" :disbaled="!enableDate" label="Fecha fin" @dateSelected="(param) => {endDate = param; $emit('getEnd', param) }" class="mx-5" />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" >        
                    <v-btn @click="filterHandler();" v-if ="!disableDates" color="primary" :disabled="!enableDate">
                        <v-icon class="mr-1">mdi-filter</v-icon>
                        Filtrar
                    </v-btn>
                </v-col>
                <v-col cols="12" class="p-1 ml-md-3" sm="12" md="7" lg="3" >
                    <v-text-field outlined dense label="Buscar..." v-model="filter" @input="handleEmptyFilter" @keyup.enter="filterHandler"></v-text-field>
                </v-col>

                <v-col cols="3" sm="12" md="4" lg="2" class="d-flex justify-content-end">
                            <v-btn  ref="filterButton" class="btn-add mr-1" @click="filterHandler"><v-icon>mdi-magnify</v-icon></v-btn>
                            <v-btn
                                class="btnBlack  mr-1"
                                style=""
                                @click="clearData"
                            >
                            <v-icon>
                                mdi-filter-remove
                            </v-icon>
                            </v-btn>
                            <v-btn
                            class="btnclean mr-1"
                            @click="showDialog = true; ">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                </v-col>
            </v-row>
          
                
       <!--  <div v-for="(column, index) in columns" :key="index">

            
            <v-row>
                <v-col cols="12" md="3" :key="index">
                    <v-autocomplete
                        v-model="column.name"
                        label ="Columnas"
                        item-text="title"
                        item-value="name"
                        :items="columnAviable"
                        attach
                        @change="verifyColumn"
                    />
                </v-col>
              
            </v-row>
        </div> -->
                   
           
            
        </div>
        <div class="mt-4" >
            <div  :style="!isAutoScrolled && 'overflow-x:scroll;'">
                <slot>
    
                </slot>
            </div>
            <div v-if="!hideLimitLabel" class="d-flex justify-center mt-1 mb-2">
                <p>En la prevista se muestras los últimos 100 registros agregados</p>
            </div>
        </div>
        <v-dialog
        scrollable
            v-model="showDialog"
            :max-width="dialogWidth"
        >
            <v-card>
                <v-card-title>
                    <h3>Filtros</h3>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="radioSelect != []">
                        <v-radio-group v-model="selectedItem">
                            <v-row class="my-3">
                                <div v-for="(radio, index) in radioSelect" :key="index">
                                    <v-col cols="12" md="4" sm="6">
                                        <v-radio
                                            :label="radio.name"
                                            :value="radio.value"
                                        ></v-radio>
                                    </v-col>
                                </div>
 
                            </v-row>
                        </v-radio-group>
                    </v-row>
                    <v-row>
                        <v-col cols="6" >
                            <v-autocomplete
                                v-model="SelectedColumn"
                                label ="Seleccionar Columna"
                                item-text="title"
                                item-value="name"
                                :items="columnAviable"
                                outlined
                                dense
                                @change="verifyColumn"
                            />
                        </v-col>
                        <v-col cols="3" >
                            <v-btn
                                class="btn-add"
                                @click="handleColumn"
                        
                            >Añadir Columna</v-btn>
                        </v-col>
                        <v-col col="3" style="margin-bottom: 25px;" >
                            <v-btn
                                class="btnclean"
                                @click="columns = []"
                        
                            >Quitar columnas</v-btn>
                        </v-col>
                    </v-row>
                    
                    
                        <v-row v-for="(column, index) in columns"  :key="index"   >
                            <v-col cols="10" >
                                <template v-if="column.type == 'select'">
                                    <v-select
                                        v-model="column.value"
                                        :items="column.options"
                                        item-text="name"
                                        item-value="value"
                                        
                                        @change="filterOptions(column)"
                                        outlined dense
                                        :label="column.title"
                                    />
                            
                                </template>
                                <template v-else>
                                    <v-text-field
                                    v-model="column.value"
                                    outlined dense
                                    :label="column.title"
                                    />
                            
                                </template>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    class="btndelete"
                                    @click="columns.splice(index,1); filterOptions()"
                            
                                ><v-icon>mdi-close</v-icon></v-btn>
                            </v-col>
                        </v-row>
                   
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn class="btndelete" @click="showDialog = false">Cerrar</v-btn>
                    <v-btn class="btn-add" @click="showDialog = false; filterHandler()">Filtrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import DatePicker from '@/components/DatePicker'


    export default {
        name: 'VersatileFilter',
        components: { DatePicker },
        props: {
            filterEndpoint: {
                type: Function,
                default: null
            },
            search: {
                type: Object,
                default: null
            },
            requiredParams: {
                type: Boolean,
                default: false
            },
            hideLimitLabel: {
                type: Boolean,
                default: false
            },
            disableDates: {
                type: Boolean,
                default: false
            },
            isAutoScrolled: {
                type: Boolean,
                default: false
            },
            twoSlots: {
                type: Boolean,
                default: false
            },
            columnsSearch: {
                type: Array,
                default: function(){
                    return []
                }
            },
            useCache: {
                type: Boolean,
                default: false
            },
            

            columnfilterEndpoint: {
                type: Function,
                default: null
            },
            radioSelect: {
                type: Array,
                default: function(){
                    return []
                }
            },
            defaulSelect: {
                type: String,
                default: null
            },
            extraParam1: {
                type: String,
                default: null
            }
        }, 
        data() {
            return {
                filter: null,
                columns: [],
                limit: 0,
                SelectedColumn: {},
                showDialog: false,
                loading: false,
                startDate: null,
                endDate: null,
                sourceFormat: "dd/mm/yyyy",
                targetFormat: "yyyy-mm-dd",
                enableDate: false,
                selectedItem:  this.defaulSelect,
            }
        },
        mounted() {
            this.limit = this.columnsSearch.length;
            this.filterHandler();
        },
        computed: {
            dialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "95%" : "40%";
    },
            paddingXs(){
                return this.$vuetify.breakpoint.smAndDown ? 'px-2' : '';
            },
            columnAviable() {
                return this.columnsSearch.filter((column) => {
                    return !this.columns.some((columnAdded) => {
                        return column.name === columnAdded.name;
                    });
                });
            },
            calculateColSize() {
        
                if (this.$vuetify.breakpoint.xsOnly) {
                    return 12;
                } else {
                    
                    return this.twoSlots ? 5 : 7
                }
            }
            
        },
        watch: {
           
            columns(){
                if(this.columns.length === 0){

                    if(!this.requiredParams)
                       this.$emit('emptyFilter');
                    else
                        this.filterHandler();
                }
            },
            enableDate( val ) {
                this.$emit( 'enabledate', val )
                if( !val ) {
                    this.filterHandler();
                }
            }
        },
        methods: {
           
            filterOptions(changued = null){
                if(changued){
                    const child = this.columns.find((column) => column.parent == changued.name);
                    if(child){
                        this.columns.forEach((column) => {
                            if(column.parent == child.name){
                                if(column.prev === undefined)
                                column.prev = column.options;
                                column.value = '';
                                column.options = column.prev.filter((option) => option.parent_id == child.value);
                            }
                        })
                    }
                }
                this.columns.forEach((column) => {
                    if(column.parent){
                       const parentCol = this.columns.find((col) => col.name == column.parent);
                       if(parentCol && parentCol.parent){
                           const grandParent = this.columns.find((col) => col.name == parentCol.parent);
                            if(grandParent){
                                if(column.prev === undefined){
                                column.prev = column.options;
                            }
                            column.options = column.prev.filter((option) => option.parent_id == parentCol.value);
                            }
                            
                       }
                       if(parentCol ){
                            if(column.prev === undefined){
                                column.prev = column.options;
                            }
                            column.options = column.prev.filter((option) => option.parent_id == parentCol.value);
                            
                       }
                       else{
                            if(column.prev != undefined){
                                column.options = column.prev;
                            }
                       }
                       
                
                    }
                })
            },
            
            verifyColumn(e){
                console.log('verificando',e);
                //verificar si la columna ya esta agregada
                let counter = 0;
                this.columns.forEach((column) => {
                    if(column.name == e){
                        counter++;
                        
                    }
                })
                if(counter > 1){
                    //eliminar la columna repetida
                    this.columns.forEach((column,index) => {
                        if(column.name == e){
                            this.columns.splice(index,1);
                        }
                    })
                    //this.$toast.error('La columna ya esta agregada');
                }
            },
            clearData() {
                console.log('limpiando');
                this.filter = '';
                this.columns = [];
                this.selectedItem = this.defaulSelect;
                this.enableDate = false;
                 if(!this.requiredParams)
                       this.$emit('emptyFilter');
                    else
                        this.filterHandler();
            },
            handleEmptyFilter() {
                if(this.filter.length === 0) {
                    if(!this.requiredParams)
                       this.$emit('emptyFilter');
                    else
                        this.filterHandler();
                }
            },
          /*   filterHandler() {
                console.log('filtrando');
                if(this.filter?.length != 0 && this.filter) {
                    console.log('filtrando',this.search);
                    this.search.filter = this.filter
                    this.filterEndpoint(this.search)
                        .then(response => {
                            console.log('filtrado', response);
                        this.$emit('filtered', response);
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    this.$emit('emptyFilter');
                }
            }, */
            filterHandler() {
                
                    this.loading = true;
                    console.log('filtrando',this.columns);
                    const columnsSend = JSON.stringify(this.columns.map((column) => ({
                        name: column.name,
                        value: column.value,
                        type: column.type
                    })));
                    let dates = {};
                    if(this.enableDate){
                        dates = {
                            startDate: this.startDate,
                            endDate: this.endDate
                        }
                    }
                    this.$emit('updateCache',{filter : columnsSend,business: this.search.business, branch: this.search.branch, search: JSON.stringify(this.search),
                        like: this.filter, selectedItem: this.radioSelect != [] ? this.selectedItem : null,
                    dates: JSON.stringify(dates)});
                    this.columnfilterEndpoint({extraParam1: this.extraParam1, filter : columnsSend,business: this.search.business, branch: this.search.branch, search: JSON.stringify(this.search),
                        like: this.filter, selectedItem: this.radioSelect != [] ? this.selectedItem : null,
                    dates: JSON.stringify(dates)})
                        .then(response => {
                            console.log('filtrado',response.length);
                            this.loading = false;
                        this.$emit('filtered', response);
                    }).catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
               
            },
            handleColumn(){
               
                
                const column = this.columnsSearch.find((column) => column.name == this.SelectedColumn);
                
                if(this.limit < this.columns.length){
                    return;
                }
                if(column){
                    
                    this.columns.find((column) => column.name == this.SelectedColumn) ? '' : this.columns.push(column);
                }
                this.filterOptions();
                
            }
        }
    }

</script>

<style scoped>
.btnBlack {
 
  background-color: rgba(60, 60, 60) !important;
    color: white;
  
  
}
    .orderedBeside {
        padding-top: 16px;
    }
</style>